import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as contentful from 'contentful'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arrow from '../../img/arrow.svg'
import arrowPrev from '../../img/arrow-prev.svg'

import MobileNav from '../../components/mobilenav'

class Proyectos extends Component {
    state = {
        posts: [],
        modalIsOpen: 0,
        settings : {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1
        },
        category: 'Residencial'
    }

    componentDidMount() {
        const client = contentful.createClient({
            space: 'p803cszn5ei0',
            environment: 'master',
            accessToken: 'Fd8jiDHJIOo1uZhU9qkyjt1GqxBHlIaAyvWrNplsaT8'
          })

          client.getEntries({content_type: 'project'})
          .then((response) => this.setState({
            posts: response.items
          })
          )
          .catch(console.error)
    }

    toggleNav = () => {
        document.getElementById('mobileNav').style.display = "flex"
        document.getElementById('menuButton').style.display = "none"
    }

    toggleModal = (id) => {
        if(this.state.modalIsOpen === 0) {
            this.setState({modalIsOpen : 1})
            document.getElementById(id).style.display = "flex"
        } else if (this.state.modalIsOpen === 1) {
            this.setState({modalIsOpen : 0})
            document.getElementById(id).style.display = "none"
        }
    }

    render() {
        return (
            <div className="Internal">
                <div className="menuButton" id="menuButton" onClick={()=>{this.toggleNav()}}></div>
                <MobileNav />
                <div className="header">
                    <h1>INTERSTICIAL&nbsp;&nbsp; <b>| &nbsp;&nbsp;Proyectos</b></h1>
                </div>

                {/* CONTENT START */}
                <div className="body large">
                    <div className="categories">
                        <div className="category" id="residencial" onClick={ ()=> { 
                            this.setState({ category: 'Residencial' }); 
                            document.getElementById("check1").style.display = "block";
                            document.getElementById("check2").style.display = "none"; 
                            } }>
                            <h2>Residencial</h2><i className="fas fa-check" id="check1"></i>
                        </div>
                        <div className="category" id="remodelacion" onClick={ ()=> { 
                            this.setState({ category: 'Remodelacion' }) 
                            document.getElementById("check1").style.display = "none";
                            document.getElementById("check2").style.display = "block"; 
                            } }>
                            <h2>Remodelación</h2><i className="fas fa-check" id="check2"></i>
                        </div>
                    </div>
                    <div className="articles projects">
                    {
                        this.state.posts.filter(post => post.fields.category === this.state.category).map((post) => {
                            let imagesCount = post.fields.galleryImages.length;

                            let createTable = () => {
                                let slider = []
                            
                                for (let i = 0; i < imagesCount; i++) {
                                  slider.push(<img src={post.fields.galleryImages[i].fields.file.url} alt='' />)
                                }
                                return slider
                              }

                            return (
                                    <div className="article project" key={post.fields.title}>
                                        <button className="thumbnail" onClick={()=> { this.toggleModal(post.fields.slug) }}><img src={post.fields.galleryImages[0].fields.file.url} alt='' /></button>
                                        <h4>{post.fields.title}</h4>
                                        {/* <p>{post.fields.extract.content[0].content[0].value}</p> */}

                                        {/* Overlay */}
                                        <div className="overlay" id={post.fields.slug}>
                                            <div className="close-button">
                                                <div className="x" onClick={()=> { this.toggleModal(post.fields.slug) }}>
                                                </div>
                                            </div>
                                           <div className="card">
                                                <div className="content">
                                                    <div className="gallery">
                                                        <Slider {...this.state.settings} className="slider">
                                                            {createTable()}
                                                        </Slider>
                                                    </div>
                                                    <div className="info">
                                                        <h2>{post.fields.title}</h2>
                                                        <p>{post.fields.description.content[0].content[0].value}</p>
                                                    </div>
                                                </div>
                                           </div> 
                                        </div>
                                    </div>
                            );
                        })
                    }
                    </div>
                </div>
                {/* CONTENT ENDS */}

                <div className="buttons">
                    <div className="previous">
                        <Link to="/">
                            <img src={arrowPrev} alt="" />
                            <h4>Inicio</h4>
                        </Link>
                    </div>
                    <div className="next">
                        <Link to="/blog">
                            <h4>Blog</h4>
                            <img src={arrow} alt="" />
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Proyectos;

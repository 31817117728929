import React, { Component } from 'react'
import { BrowserRouter as Router, Route} from 'react-router-dom'
import './App.scss'
import Home from './pages/home'
import Contacto from './pages/contacto'
import Project from './pages/proyectos'
import Blog from './pages/blog'

class App extends Component {

  showSettings (event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Route exact path='/' component={Home} />
          <Route path='/proyectos' component={Project} />
          <Route path='/blog' component={Blog} />
          <Route path='/contacto' component={Contacto} />
        </Router>
      </div>
    )
  }
}

export default App;


import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../App.scss'

import logo from '../../img/isotipo.svg'
import arrow from '../../img/arrow.svg'

class Home extends Component {
    render() {
        return (
            <div className="Home">
                <div className="sidebar">
                    <div className="brand">
                        <img src={logo} alt=""/>
                        <h2>INTERSTICIAL</h2>
                        <h3>Arq.Studio</h3>
                        <h5>Arquitectura &<br/>Diseño Interior</h5>
                    </div>
                    <div className="links">
                        <nav>
                            <li><Link to="/">Inicio</Link></li>
                            <li><Link to="/proyectos">Proyectos</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/contacto">Contacto</Link></li>
                        </nav>
                        <div className="social-media">
                            <a href="https://www.instagram.com/intersticial.arqstudio/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                            <a href="https://wa.me/593998008413?text=Hola%20acabo%20de%20ver%20su%20sitio%20web.%20Me%20gustaría%20conocer%20más%20sobre%20sus%20servicios" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="render-container">
                    </div>
                    <div className="caption-container">
                        <hr/>
                        <h1>BIENVENIDO<br/>A <b>INTERSTICIAL</b> </h1>
                        <p id="quote">“Architecture can render vivid to us who we might  ideally be:”</p>
                        <p id="author">Mies van der Rohe, 1930</p>
                        <p id="description">
                        Conducidos por el potencial de los espacios intermedios, lo indefinido y sus multiples posibilidades dentro del diseño, surge INTERSTICIAL  Arq.Studio con la iniciativa de crear experiencias en resonancia con la naturaleza y sensaciones inspiradas en sus usuarios. 
                        </p>
                        <a href="/proyectos">
                            Conoce más
                            <img src={arrow} alt="" />
                        </a>
                    </div>
                    <div className="copyright">
                        © Intersticial. Todos los derechos reservados.
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;

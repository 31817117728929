import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../App.scss'
import logo from '../../img/isotipo.svg'

export default class MobileNav extends Component {

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    close = () => {
        document.getElementById('mobileNav').style.display = "none"
        document.getElementById('menuButton').style.display = "flex"
    }

    escFunction(event){
        if(event.keyCode === 27 && document.getElementById("mobileNav").style.display === 'flex') {
            console.log('cerrar')
            document.getElementById("mobileNav").style.display = 'none'
            document.getElementById('menuButton').style.display = "block"
        }
    }

    render() {
        return (
            <div className="mobileNav" id="mobileNav">
                <nav className="nav-container">
                    <div className="close-button">
                        <div className="x" onClick={()=>{ this.close() }}>
                            {/* <div className="outer">
                                <div className="inner">
                                    <label>Cerrar</label>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="sidebar">
                    <div className="brand">
                        <img src={logo} alt=""/>
                        <h2>INTERSTICIAL</h2>
                        <h3>Arq.Studio</h3>
                        <h5>Arquitectura &<br/>Diseño Interior</h5>
                    </div>
                    <div className="links">
                        <nav>
                        <li><Link to="/">Inicio</Link></li>
                        <li><Link to="/proyectos">Proyectos</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/contacto">Contacto</Link></li>
                        </nav>
                        <div className="social-media">
                        <a href="https://www.instagram.com/intersticial.arqstudio/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                        <a href="https://wa.me/593998008413?text=Hola%20acabo%20de%20ver%20su%20sitio%20web.%20Me%20gustaría%20conocer%20más%20sobre%20sus%20servicios" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a>
                        </div>
                    </div>
                    </div>
                </nav>
            </div>
        )
    }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import arrow from '../../img/arrow.svg'
import arrowPrev from '../../img/arrow-prev.svg'

import MobileNav from '../../components/mobilenav'

class Contacto extends Component {

    toggleNav = () => {
        document.getElementById('mobileNav').style.display = "flex"
        document.getElementById('menuButton').style.display = "none"
    }

    render() {
        return (
            <div className="Internal">
                <div className="menuButton" id="menuButton" onClick={()=>{this.toggleNav()}}></div>
                <MobileNav />
                <div className="header">
                    <h1>INTERSTICIAL&nbsp;&nbsp; <b>| &nbsp;&nbsp;Contacto</b></h1>
                </div>

                {/* CONTACT BEGINGS HERE */}
                <div className="body">
                    <h4>Envíanos tu mensaje.</h4>
                    <div className="form-container" id="form">
                    <form action="https://formcarry.com/s/x9lrDEWYeqp" method="POST">
                        <input type="text" name="Nombre" placeholder="Nombre y apellido"/>
                        <input type="email" name="Email" placeholder="Correo electrónico"/>
                        <textarea name="Mensaje" placeholder="Escriba su mensaje"></textarea>
                        <input type="submit" value="Enviar" id="button"/>
                    </form>
                    </div>
                    <div className="icons">
                        <a href="https://www.instagram.com/intersticial.arqstudio/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                        <a href="https://wa.me/593998008413?text=Hola%20acabo%20de%20ver%20su%20sitio%20web.%20Me%20gustaría%20conocer%20más%20sobre%20sus%20servicios" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a>
                    </div>
                    <div className="location">
                        <h4>Quito, Ecuador</h4>
                    </div>
                </div>
                {/* CONTACT ENDS HERE */}

                <div className="buttons">
                    <div className="previous">
                        <Link to="/blog">
                            <img src={arrowPrev} alt="" />
                            <h4>Blog</h4>
                        </Link>
                    </div>
                    <div className="next">
                        <Link to="/">
                            <h4>Inicio</h4>
                            <img src={arrow} alt="" />
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contacto;
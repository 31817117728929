import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as contentful from 'contentful'
import arrow from '../../img/arrow.svg'
import profile from '../../img/profile.png'
import arrowPrev from '../../img/arrow-prev.svg'

import MobileNav from '../../components/mobilenav'

class Blog extends Component {
    state = {
        posts: []
    }

    componentDidMount() {
        const client = contentful.createClient({
            space: 'p803cszn5ei0',
            environment: 'master',
            accessToken: 'Fd8jiDHJIOo1uZhU9qkyjt1GqxBHlIaAyvWrNplsaT8'
          })

          client.getEntries({content_type: 'blogPost'})
          .then((response) => this.setState({
            posts: response.items
          })
          )
          .catch(console.error)
    }

    toggleNav = () => {
        document.getElementById('mobileNav').style.display = "flex"
        document.getElementById('menuButton').style.display = "none"
    }

    collapse (key, btn) {
        const e = document.getElementById(key);
        var text = document.getElementById(btn).firstChild;

        if(e.classList.contains('closed')) {
            e.classList.remove('closed')
            text.data = "Leer menos"
        } else {
            e.classList.add('closed');
            text.data = "Leer más"
        }
    }

    render() {
        return (
            <div className="Internal">
                <div className="menuButton" id="menuButton" onClick={()=>{this.toggleNav()}}></div>
                <MobileNav />
                <div className="header">
                    <h1>INTERSTICIAL&nbsp;&nbsp; <b>| &nbsp;&nbsp;Blog</b></h1>
                </div>

                {/* BLOG BEGINGS HERE */}
                <div className="body">
                    <div className="articles">
                    {
                        this.state.posts.map((post) => {
                            return (
                                    <div className="article" key={post.fields.title}>
                                        <img src={post.fields.heroImage.fields.file.url} alt='' />
                                        <h2>{post.fields.title}</h2>
                                        <h3>{post.fields.date}</h3>
                                        <h4>{post.fields.description}</h4>
                                        <p id={post.fields.slug} className="closed" style={{'whiteSpace':'pre-line'}}>{post.fields.body}</p>
                                        <button onClick={ ()=>{this.collapse( post.fields.slug, post.fields.title )} } id={post.fields.title}>Leer más</button>
                                    </div>
                            );
                        })
                    }
                    </div>
                </div>
                {/* BLOG ENDS HERE */}

                {/* Personal info */}
                <div className="profile">
                    <div className="photo">
                        <img src={profile} alt=''/>
                    </div>
                    <div className="info">
                        <div className="caption">
                            <h1>LAURA CHAVEZ-DAVILA</h1>
                        </div>
                        <div className="text">
                            <p>
                            Fundadora y directora creativa de Intersticial Arq. Studio. Arquitecta por la Universidad San Francisco de Quito. Ha realizado diversas pasantías, cursos y especializaciones en visualización arquitectónica, fotografía, dibujo a mano alzada y arte. De pensamiento disruptivo, intuitiva y creativa con un genuino interés en los usuarios y sus aspiraciones busca brindar un acercamiento hacia un diseño arquitectónico e interior sostenible.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="buttons">
                    <div className="previous">
                        <Link to="/proyectos">
                            <img src={arrowPrev} alt="" />
                            <h4>Proyectos</h4>
                        </Link>
                    </div>
                    <div className="next">
                        <Link to="/contacto">
                            <h4>Contacto</h4>
                            <img src={arrow} alt="" />
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Blog;